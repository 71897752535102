import React from "react";

const IconCaretRight = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 19C8.44772 19 8 18.5523 8 18V6C8 5.44771 8.44772 5 9 5C9.55228 5 10 5.44771 10 6V18C10 18.5523 9.55228 19 9 19Z"
        fill={fill}
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20006 18.5999C7.86869 18.1581 7.95823 17.5313 8.40006 17.1999L15.3334 11.9999L8.40006 6.79994C7.95823 6.46857 7.86869 5.84177 8.20006 5.39994C8.53143 4.95811 9.15823 4.86857 9.60006 5.19994L17.6001 11.1999C17.8519 11.3888 18.0001 11.6852 18.0001 11.9999C18.0001 12.3147 17.8519 12.6111 17.6001 12.7999L9.60006 18.7999C9.15823 19.1313 8.53143 19.0418 8.20006 18.5999Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCaretRight;
