import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const settings = {
    dots: false,
    arrows: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="partners">
      <div className="items">
        <Slider {...settings}>
          <a href="https://www.bestchange.ru/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-1.png")} alt="" />
          </a>
          <a href="https://blockchair.com/ru/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-2.png")} alt="" />
          </a>
          <a href="https://wellcrypto.io/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-3.png")} alt="" />
          </a>
          <a href="https://scanbit.com.ua/en" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-4.png")} alt="" />
          </a>
          <a href="https://www.okchanger.com/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-5.png")} alt="" />
          </a>
          <a href="https://e-mon.cc/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-6.png")} alt="" />
          </a>
          <a href="https://pro-obmen.ru/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-7.png")} alt="" />
          </a>
          <a href="https://bits.media/" target="_blank" rel="noreferrer">
            <img src={require("../../assets/img/partners/img-8.png")} alt="" />
          </a>
        </Slider>
      </div>
    </section>
  );
};

export default Partners;
