import React from "react";
import { ConfigProvider } from "antd";
import ruRu from "antd/es/locale/ru_RU";
import Routes from "./router/Routes";

function App() {
  return (
    <ConfigProvider locale={ruRu}>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
