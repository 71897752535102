import React from "react";
import { Col, Row } from "antd";

function Advantages() {
  return (
    <section className="advantages" id="advantages">
      <Row className="info mb-50">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <p>
            Сервисы электронной почты <span>@protonmail.com</span>,{" "}
            <span>@proton.me</span>,<span>@startmail.com</span>,{" "}
            <span>@tutanota.com</span> и др. - заблокированы в России по решению
            Роскомнадзора. Письма с таких сервисов не поступают. Пожалуйста,
            используйте иные почтовые сервисы.
          </p>
          <p>
            Все транзакции проходят AML проверку. Высокорисковые транзакции
            обрабатываются согласно п.п <span>13.6</span>, <span>13.7</span>,{" "}
            <span>13.8</span>, <span>13.9</span>
          </p>
          <p>
            Заявка обрабатывается в ручном режиме, курс плавающий. Фиксация
            курса происходит в момент получения 2 подтверждений сетью и покупки
            монет по маркету. Сумма к получению будет пересчитана на момент
            открытия ордера по маркету по средней цене за конвертацию.
            Максимальная величина изменения получаемой суммы не более 5%. Для
            расчета мы используем курс биржи Binance. Выход транзакции в сеть
            может занимать до 30 мин.
          </p>
        </Col>
      </Row>
      <Row align="middle">
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className="item">
            <h4>ВЫГОДНЫЙ ОБМЕН 24/7</h4>
            <p>Качественно меняем валюту онлайн вот уже на протяжении 2 лет.</p>
          </div>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className="item">
            <h4>100% ГАРАНТИЯ ОБМЕНА</h4>
            <p>
              Многочисленные положительные отзывы подтверждают высокое качество
              нашей работы.
            </p>
          </div>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className="item">
            <h4>БЫСТРЫЙ ОБМЕН ДО 7 МИН.</h4>
            <p>
              Нас любят не только за надежность и лучший курс, но и за быстрый
              обмен.
            </p>
          </div>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <div className="item">
            <h4>ЭКОНОМИЧНЫЙ ОБМЕН</h4>
            <p>
              У нас всегда лояльные курсы. Мы всегда открыты к сотрудничеству,
              обращайтесь.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default Advantages;
