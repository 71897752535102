import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  IconChatActivity,
  IconClock,
  IconEnvelope,
  IconExclamationSquare,
} from "../utils/Icons";
import { send } from "emailjs-com";
import useMessage from "../hooks/useMessage";

const { TextArea } = Input;

function Contact() {
  const [defaultValues, setDefaultValues] = useState({
    name: "",
    email: "",
    text: "",
  });

  const validateMessages = {
    required: "${label} обязательное поле!",
    types: {
      email: "${label} введите почту!",
    },
  };

  const { successResult, errorResult, contextHolder } = useMessage();

  const handleChange = (e) => {
    setDefaultValues({ ...defaultValues, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    setDefaultValues({
      ...defaultValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (defaultValues.name === "" || defaultValues.email === "") {
      errorResult("Пожалуйста, заполните все обязательные поля.");
    } else {
      send(
        "service_e2kb56b",
        "template_2iehmrg",
        defaultValues,
        "zWUGrm_-1m3kIm23w"
      ).then(
        (result) => {
          successResult("Спасибо, ваше сообщение отправлено.");
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  return (
    <section className="contact">
      <Row align="middle">
        <Col lg={12} md={24} sm={24} xs={24} className="info">
          <h2>Контактная информация</h2>
          <p>
            По всем возникшим вопросам обращаться к онлайн - оператору на сайте
            или напишите к нам на почту.
          </p>
          <div className="social">
            <div className="item">
              <IconEnvelope width="28" height="28" fill="#fff" />
              <div className="desc">
                <b>Поддержка по почте:</b>
                <span>treexchange.crypto@gmail.com</span>
              </div>
            </div>
            <div className="item">
              <IconChatActivity width="28" height="28" fill="#fff" />
              <div className="desc">
                <b>Чат онлайн:</b>
                <span>
                  <a href="javascript:jivo_api.open()">
                    Открыть чат для поддержки
                  </a>
                </span>
              </div>
            </div>
            <div className="item">
              <IconClock width="28" height="28" fill="#fff" />
              <div className="desc">
                <b>Время работы</b>
                <span>Круглосуточно</span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className="box">
            <p className="info">
              <IconExclamationSquare width="20" height="20" fill="#ffd700" />
              Заполните контактную форму
            </p>
            <p className="small">
              Не стесняйтесь связаться с нами, мы не спамим вашу электронную
              почту
            </p>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              onFinish={onSubmit}
              validateMessages={validateMessages}
            >
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"name"}
                    label={"Имя *"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={"Введите ваше имя"}
                      value={defaultValues.name}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"email"}
                    label={"Email *"}
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      placeholder={"Введите ваш email"}
                      value={defaultValues.name}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item label="Сообщение">
                    <TextArea
                      id="text"
                      rows={4}
                      value={defaultValues.text}
                      placeholder="Введите ваше cообщение"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="d-flex justify-center mt-20">
                <Button htmlType="submit">Отправить</Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      {contextHolder}
    </section>
  );
}

export default Contact;
