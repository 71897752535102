export const CRYPTOCURRENCY_ARRAY = [
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin",
    memo: false,
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ethereum",
    memo: false,
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "ethereumclassic",
    symbol: "ETC",
    name: "Ether Classic ETC",
    memo: false,
    icon: require("../assets/img/cryptocurrency/etc.svg").default,
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "LTC",
    memo: false,
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "tether",
    symbol: "USDC",
    name: "Tether TRC20",
    memo: false,
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "ripple",
    symbol: "XRP",
    name: "Ripple",
    memo: false,
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "stellar",
    symbol: "XLM",
    name: "Stellar",
    memo: "5bd7ac51983e11ee89850242ac11",
    icon: require("../assets/img/cryptocurrency/xlm.svg").default,
  },
  {
    id: "dogecoin",
    symbol: "DOGE",
    name: "Dogecoin",
    memo: false,
    icon: require("../assets/img/cryptocurrency/doge.svg").default,
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "Tron",
    memo: false,
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana",
    memo: false,
    icon: require("../assets/img/cryptocurrency/sol.svg").default,
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero",
    memo: false,
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon",
    memo: false,
    icon: require("../assets/img/cryptocurrency/matic.svg").default,
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot",
    memo: false,
    icon: require("../assets/img/cryptocurrency/dot.svg").default,
  },
  {
    id: "binancecoin",
    symbol: "BNB",
    name: "Binance Coin BEP20",
    memo: false,
    icon: require("../assets/img/cryptocurrency/bnb.svg").default,
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS",
    memo: false,
    icon: require("../assets/img/cryptocurrency/eos.svg").default,
  },
  {
    id: "usd",
    symbol: "USD",
    name: "USD",
    memo: false,
    icon: require("../assets/img/cryptocurrency/usd.svg").default,
  },
  {
    id: "eur",
    symbol: "EUR",
    name: "EUR",
    memo: false,
    icon: require("../assets/img/cryptocurrency/eur.svg").default,
  },
  {
    id: "gbp",
    symbol: "GBP",
    name: "GBP",
    memo: false,
    icon: require("../assets/img/cryptocurrency/gbp.svg").default,
  },
];
