import React from "react";
import Banner from "../components/Home/Banner";
import Advantages from "../components/Home/Advantages";
import Partners from "../components/Home/Partners";

function Home() {
  return (
    <main>
      <Banner />
      <Advantages />
      <Partners />
    </main>
  );
}

export default Home;
