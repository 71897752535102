import React from "react";

const IconExclamationSquare = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M7 2.99829C4.791 2.99829 3 4.78929 3 6.99829V16.9983C3 19.2073 4.791 20.9983 7 20.9983H17C19.209 20.9983 21 19.2073 21 16.9983V6.99829C21 4.78929 19.209 2.99829 17 2.99829H7ZM7 4.99829H17C18.105 4.99829 19 5.89329 19 6.99829V16.9983C19 18.1033 18.105 18.9983 17 18.9983H7C5.895 18.9983 5 18.1033 5 16.9983V6.99829C5 5.89329 5.895 4.99829 7 4.99829Z"
        fill={fill}
        fillOpacity="0.4"
      />
      <path
        d="M12 7C11.448 7 11 7.448 11 8V13C11 13.552 11.448 14 12 14C12.552 14 13 13.552 13 13V8C13 7.448 12.552 7 12 7ZM12 15C11.448 15 11 15.448 11 16C11 16.552 11.448 17 12 17C12.552 17 13 16.552 13 16C13 15.448 12.552 15 12 15Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconExclamationSquare;
