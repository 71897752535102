import { Button, Col, Form, Input, Modal, QRCode, Row, Steps } from "antd";
import React, { useEffect, useState } from "react";
import {
  IconArrowLeftShort,
  IconCaretRight,
  IconClose,
} from "../../utils/Icons";
import useMessage from "../../hooks/useMessage";
import { send } from "emailjs-com";
import ApplicationSuccess from "./ApplicationSuccess";
import { CRYPTOCURRENCY_ARRAY } from "../../constants/CryptocurrencyArray";
import { getActualSymbol } from "../Home/Exchange";

function Application({ show, handleClose, state, walletAddress }) {
  const [defaultValues, setDefaultValues] = useState({
    name: "",
    email: "",
    phone: "",
    telegram: "",
    wallet: "",
    amount: "",
    resultAmount: "",
    currencyFirst: "",
    currencySecond: "",
  });

  const [current, setCurrent] = useState(0);
  const [randomNumber, setRandomNumber] = useState(null);
  const [modalSuccessShow, setModalSuccessShow] = useState(false);

  const { errorResult, contextHolder } = useMessage();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const validateMessages = {
    required: "${label} обязательное поле!",
    types: {
      email: "${label} введите почту!",
    },
  };

  const isFormValid = () => {
    return !(
      defaultValues.name === "" ||
      defaultValues.email === "" ||
      defaultValues.phone === "" ||
      defaultValues.wallet === ""
    );
  };

  const generateRandomNumber = () => {
    const minNumber = 1000;
    const maxNumber = 9999;
    const newRandomNumber =
      Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
    setRandomNumber(`#0${newRandomNumber}`);
  };

  const handleChange = (e) => {
    setDefaultValues({ ...defaultValues, [e.target.id]: e.target.value });
  };

  const onSubmit = () => {
    if (!isFormValid()) {
      errorResult("Пожалуйста, заполните все обязательные поля.");
    } else {
      send(
        "service_e2kb56b",
        "template_0p2blbs",
        defaultValues,
        "zWUGrm_-1m3kIm23w"
      ).then(
        (result) => {
          handleClose();
          setModalSuccessShow(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  useEffect(() => {
    generateRandomNumber();

    setDefaultValues({
      ...defaultValues,
      amount: state.amount,
      currencyFirst: state.currencyFrom,
      currencySecond: state.currencyTo,
      resultAmount: state.result,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amount, state.currencyFrom, state.currencyTo]);

  console.log({ defaultValues });

  const steps = [
    {
      title: "Заполнить информацию",
      content: (
        <Row justify="center">
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name={"name"}
              label={"Имя *"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={"Введите ваше имя"}
                value={defaultValues.name}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name={"email"}
              label={"Email *"}
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input
                placeholder={"Введите ваш Email"}
                value={defaultValues.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name={"phone"}
              label={"Телефон *"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={"Введите ваш номер телефона"}
                value={defaultValues.phone}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item name={"telegram"} label={"Telegram"}>
              <Input
                placeholder={"Введите ваш номер телефона"}
                value={defaultValues.telegram}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name={"wallet"}
              label={"Кошелек *"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={"Введите ваш Кошелек"}
                value={defaultValues.wallet}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="badge">
              <p className="title">
                <IconCaretRight width="20" height="20" fill="#fff" />
                Ваш обмен:
              </p>
              <span>
                Вы отдаете{" "}
                <s>
                  {state.amount} {getActualSymbol(state.currencyFrom)}
                </s>
                , получаете{" "}
                <s>
                  {state.result} {getActualSymbol(state.currencyTo)}
                </s>
              </span>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Выполнить перевод",
      content: (
        <Row>
          <Col lg={7} md={7} sm={24} xs={24}>
            <div className="qrcode">
              <p>QRCode:</p>
              <QRCode value={walletAddress} />
            </div>
          </Col>
          <Col lg={17} md={17} sm={24} xs={24}>
            <p className="result-modal">
              Переведите указанную сумму:
              <s className="text-capitalize">
                {state.amount} {getActualSymbol(state.currencyFrom)}
              </s>
            </p>
            <p className="result-modal mb-50">
              На данный кошелек:
              <s>{walletAddress}</s>
              {state.currencyFrom === "stellar" &&
                `Memo ID: ${CRYPTOCURRENCY_ARRAY[5].memo}`}
              {/* {state.currencyFrom === "ripple" && (
                <span className="tag">
                  в поле <i>'Destination tag'</i> обязательно укажите:{" "}
                  <b>635758</b>
                </span>
              )} */}
            </p>
          </Col>
        </Row>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Modal
        title={`Заявка ${randomNumber}`}
        open={show}
        centered
        footer={null}
        width={800}
        onCancel={handleClose}
        closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          onFinish={onSubmit}
          validateMessages={validateMessages}
        >
          <Steps current={current} items={items} />
          <div>{steps[current].content}</div>
          <div className="d-flex justify-center">
            {current < steps.length - 1 && (
              <Button onClick={() => next()} disabled={!isFormValid()}>
                Далее
              </Button>
            )}
            {current > 0 && (
              <Button type="text" onClick={() => prev()}>
                <IconArrowLeftShort width="22" height="22" fill="#fff" />
                Назад
              </Button>
            )}
            {current === steps.length - 1 && (
              <div className="d-flex justify-center">
                <Button htmlType="submit">Отправить</Button>
              </div>
            )}
          </div>
        </Form>

        {contextHolder}
      </Modal>

      <ApplicationSuccess
        show={modalSuccessShow}
        handleClose={() => setModalSuccessShow(false)}
      />
    </>
  );
}

export default Application;
