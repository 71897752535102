import React from "react";
import { IconClose } from "../../utils/Icons";
import { Modal } from "antd";

function ApplicationSuccess({ show, handleClose }) {
  return (
    <Modal
      title={`Ваша заявка принята`}
      open={show}
      centered
      footer={null}
      onCancel={handleClose}
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
    >
      <div className="success">
        <img
          src={require("../../assets/img/vector-success.svg").default}
          alt=""
        />
        <h1>
          Ваша заявка успешно принята, ожидайте ответа от нашего менеджера!
        </h1>
      </div>
    </Modal>
  );
}

export default ApplicationSuccess;
