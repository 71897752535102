import React from "react";
import { Header } from "antd/es/layout/layout";

function NavBar() {
  return (
    <Header>
      <a href="/" className="logo">
        <img src={require("../assets/img/logo.png")} alt="" />
        <span>Treexchange</span>
      </a>
      <ul>
        <li>
          <a href="/">Главная</a>
        </li>
        <li>
          <a href="/tеrms_of_service">Правила обмена</a>
        </li>
        <li>
          <a href="/privacy_policy">AML/KYC</a>
        </li>
        <li>
          <a href="/contact">Контакты</a>
        </li>
      </ul>
    </Header>
  );
}

export default NavBar;
