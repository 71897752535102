import React from "react";

const IconChatActivity = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M4.005,21.749a1.76,1.76,0,0,1-1.664-2.308l.841-2.5a1.181,1.181,0,0,0-.027-.858,9.77,9.77,0,1,1,4.771,4.767,1.189,1.189,0,0,0-.875-.026l-2.489.836A1.77,1.77,0,0,1,4.005,21.749Zm8-18A8.236,8.236,0,0,0,4.513,15.442a2.68,2.68,0,0,1,.094,1.963l-.843,2.512a.24.24,0,0,0,.064.255.244.244,0,0,0,.257.064l2.5-.839a2.679,2.679,0,0,1,1.982.094A8.246,8.246,0,1,0,12,3.75ZM8.53,14.13l2.3-2.232,1.271,1.269a1.552,1.552,0,0,0,2.19,0L16.53,10.93a.75.75,0,0,0-1.06-1.06L13.164,12.1l-1.271-1.268a1.553,1.553,0,0,0-2.191,0L7.47,13.07a.75.75,0,1,0,1.06,1.06Z"
      />
    </svg>
  );
};

export default IconChatActivity;
