import React from "react";

const IconArrowLeftShort = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H9.41421L8.41406 12.0002L9.41391 13H17Z"
        fill={fill}
        fillOpacity="0.4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4141 7.00015L8.41406 12.0002L13.4141 17.0001L11.9998 18.4144L6.29274 12.7073C6.1052 12.5197 5.99984 12.2654 5.99984 12.0002C5.99984 11.7349 6.1052 11.4806 6.29274 11.293L11.9998 5.58594L13.4141 7.00015Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrowLeftShort;
