import { Col, Row } from "antd";
import React from "react";

const AML = () => {
  return (
    <main>
      <div className="rules">
        <section>
          <Row>
            <Col lg={24} md={24} xs={24}>
              <h2>Политика AML/KYC</h2>
              <p>
                Политика AML направлена на противодействие отмыванию средств,
                полученных преступным путем. Она включает в себя комплекс мер по
                выявлению рискованных транзакций, их анализу и последующей
                обработке.
              </p>

              <h4>Сбор данных:</h4>
              <p>
                При регистрации у нас сохраняется ваш адрес электронной почты и
                IP-адрес.
                <br />
                При создании заявок в них могут фиксироваться:
                <br />- номера карт и счетов
                <br />- ФИО держателя карты
                <br />- номера кошельков электронных валют
                <br />- адреса криптовалют
                <br />- файлы, отправляемые клиентом при верификации карт и при
                общении во внутреннем чате
                <br />- текст, отправляемый клиентом во внутреннем чате
                <br />- документы, удостоверяющие личность в случае проведения
                верификации
              </p>

              <h4>Собранные данные могут быть использованы:</h4>
              <p>
                - для обеспечения функционирования нашего сайта и для
                предоставления вам информации и услуг
                <br />
                - для оказания вам услуг технической поддержки
                <br />
                - для внутреннего анализа и исследований в целях улучшения
                нашего сайта, а также других продуктов и услуг
                <br />
                - для отправки вам административных сообщений (к примеру, в
                случае, если вы забыли пароль)
                <br />- для обнаружения и предотвращения случаев мошенничества и
                злоупотребления нашим сайтом или услугами
              </p>

              <p>
                Сервис не разглашает личные данные пользователя третьим лицам.
                Только в исключительных обстоятельствах мы можем раскрыть ваши
                персональные данные по требованию законодательства, а также в
                целях защиты себя или третьих лиц от ущерба и противоправных
                действий.
              </p>

              <p>
                ExBox использует сервисы AMLBot и Match Systems для анализа
                входящих транзакций. Ваша транзакция может быть оценена как
                высокорискованная в двух случаях:
                <br />
                1. Если показатели риска средств в составе транзакции превышают
                допустимые пределы, указанные в конце страницы.
                <br />
                2. Если транзакция была приостановлена криптовалютной биржей, на
                которую мы принимаем оплату, на основание дополнительной
                информацией, которой у нас может не быть. Если ваша транзакция
                соответствует одному из двух случаев, то обработка заявки будет
                приостановлена и мы затребуем верификацию личности и обоснование
                источника средств. Обратите внимание, что без верификации нельзя
                будет запросить отмену заявки и возврат средств.
              </p>

              <h4>
                После получения достаточных данных для верификации, начинается
                их проверка:
              </h4>
              <p>
                1. Если заявка была приостановлена оператором ExBox, проверка
                данных будет проведена администратором сервиса в рабочие часы.
                <br />
                2. Если заявка была приостановлена по инициативе криптовалютной
                биржи, мы отправляем ваши данные в техподдержку и ожидаем их
                решения: 2.1. Как правило проверка документов составляет до 7
                рабочих дней.
                <br />
                2.2. Если блокировка средств на криптовалютной бирже произошла
                по требованию внешнего регулятора, то средства будут заморожены
                до момента получения разрешения на их разморозку и возврат. До
                этого момента, что-либо сделать будет невозможно.
              </p>

              <h4>Для верификации могут быть запрошены следующие данные:</h4>
              <p>
                1. Фото одного из документов (паспорт, ID-карта или водительское
                удостоверение).
                <br />
                2. Селфи с этим документом и листом, на котором от руки будет
                написана сегодняшняя дата и подпись.
                <br />
                Для подтверждения происхождения средств будет необходимо
                ответить на вопросы:
                <br />- Посредством какой платформы средства поступили к вам?
                Подтвердите это скриншотами и ссылками на транзакции. - За какую
                услугу вы получили средства?
                <br />- На какую сумму была транзакция, а также дата и время ее
                проведения?
                <br />- Через какое контактное лицо вы общались с отправителем
                средств? Предоставьте скриншоты переписки с отправителем, где
                можно будет увидеть подтверждение отправки средств. Сервис
                оставляет за собой право расширить список требований исходя из
                конкретной ситуации.
              </p>

              <p>
                Понимая указанные требования, Пользователь обязуется
                предоставить данные для верификации и ожидать решения без
                предъявления к Сервису каких-либо претензий.
              </p>

              <h4>Допустимые значения категорий риска:</h4>
              <p>
                - до 0% для Darknet Marketplace, Darknet Service, Drugs, Child
                Abuse, Stolen Credit Cards, Enforcement Action, Blackmail,
                Personal data EU, Personal data US, Smuggling drugs, Smuggling
                precursors, Smuggling people, Smuggling weapons, Smuggling,
                Illegal migration, Human trafficking, Fake documents, Fake
                document rendering, Illegal weapons, Fraudulent Exchange,
                Gambling, Illegal Service, PEP, Сorruption, Ransom, Malware
                (excluding Ransom), DDOS service, Phishing service, Stolen
                Coins, Scam ICO, High Risk country, Sanction country, Terrorism,
                State bodies
                <br />- до 5% для Scam
                <br />- до 10% для Precursors, China precursors manufacturing,
                Personal data RU, Personal data CIS, Personal data other, Stolen
                DataBase, Laundering of money, Illegal financial transactions,
                Paramilitary organization, Cracked software
                <br />- до 25% для Mixing Service, Online Marketplace, Bribge
                <br />- до 50% для Exchange With High ML Risk, Exchange With
                Very High ML Risk, DEX (excluding Bridges), P2P Exchange With
                High ML Risk, P2P Exchange With Very High ML Risk, OTC Exchange
                With High ML Risk, OTC Exchange With Very High ML Risk, ATM
              </p>
            </Col>
          </Row>
        </section>
      </div>
    </main>
  );
};

export default AML;
