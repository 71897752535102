import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components List
import NavBar from "../components/NavBar";
import Home from "../pages/Home";
import Rules from "../pages/Rules";
import AML from "../pages/AML";
import Footer from "../components/Footer";
import Contact from "../pages/Contact";

function Routes() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy_policy" component={AML} />
          <Route path="/tеrms_of_service" component={Rules} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default Routes;
