import React from "react";

const IconLock = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M17.417,16V12a2.442,2.442,0,0,0-2-2.671V8.667a3.417,3.417,0,0,0-6.834,0v.662a2.442,2.442,0,0,0-2,2.671v4a2.5,2.5,0,0,0,2.75,2.75h5.334A2.5,2.5,0,0,0,17.417,16ZM12,6.75a1.919,1.919,0,0,1,1.917,1.917V9.25H10.083V8.667A1.919,1.919,0,0,1,12,6.75ZM15.917,16c0,.923-.327,1.25-1.25,1.25H9.333c-.923,0-1.25-.327-1.25-1.25V12c0-.923.327-1.25,1.25-1.25h5.334c.923,0,1.25.327,1.25,1.25ZM13.27,13.333a1.23,1.23,0,0,1-.52.988v1.012a.75.75,0,0,1-1.5,0V14.3a1.228,1.228,0,0,1-.485-.963,1.246,1.246,0,0,1,1.245-1.25h.01A1.25,1.25,0,0,1,13.27,13.333ZM21.75,4.5V8a.75.75,0,0,1-1.5,0V4.5c0-.589-.161-.75-.75-.75H16a.75.75,0,0,1,0-1.5h3.5A2.067,2.067,0,0,1,21.75,4.5ZM2.25,8V4.5A2.067,2.067,0,0,1,4.5,2.25H8a.75.75,0,0,1,0,1.5H4.5c-.589,0-.75.161-.75.75V8a.75.75,0,0,1-1.5,0Zm6.5,13a.75.75,0,0,1-.75.75H4.5A2.067,2.067,0,0,1,2.25,19.5V16a.75.75,0,0,1,1.5,0v3.5c0,.589.161.75.75.75H8A.75.75,0,0,1,8.75,21Zm13-5v3.5a2.067,2.067,0,0,1-2.25,2.25H16a.75.75,0,0,1,0-1.5h3.5c.589,0,.75-.161.75-.75V16a.75.75,0,0,1,1.5,0Z"
      />
    </svg>
  );
};

export default IconLock;
