import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, InputNumber, Row, Select } from "antd";
import { IconAngleDownSquare } from "../../utils/Icons";
import { CRYPTOCURRENCY_ARRAY } from "../../constants/CryptocurrencyArray";
import Application from "../Modal/Application";
import useMessage from "antd/es/message/useMessage";

const { Option } = Select;

export const getActualSymbol = (symbol) => {
  if (symbol && symbol.startsWith("USDC")) {
    return "USDT";
  }
  return symbol || "";
};

function Exchange() {
  const [state, setState] = useState({
    amount: null,
    currencyTo: "ETH",
    currencyFrom: "BTC",
    currencyToInfo: CRYPTOCURRENCY_ARRAY[1],
    currencyFromInfo: CRYPTOCURRENCY_ARRAY[0],
    result: "",
    exchangeRate: "",
  });

  const [walletAddress, setWalletAddress] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { successResult, errorResult, contextHolder } = useMessage();

  const isFiatCurrency = (currency) => {
    const fiatCurrencies = ["USD", "EUR", "GBP", "AUD", "CAD"];
    const currencySymbol = currency.substring(0, 3).toUpperCase();
    return fiatCurrencies.includes(currencySymbol);
  };

  const fetchRate = async (currency) => {
    const actualCurrency = getActualSymbol(currency);
    const url = isFiatCurrency(currency)
      ? `https://api.exchangerate-api.com/v4/latest/${actualCurrency}`
      : `https://api.binance.com/api/v3/ticker/price?symbol=${actualCurrency}USDT`;

    return fetch(url);
  };

  const fetchExchangeRate = async () => {
    try {
      let fromCurrencyToUsdRate;
      let toCurrencyToUsdRate;

      const fromResponse = await fetchRate(state.currencyFrom);
      const fromCurrencyData = await fromResponse.json();
      fromCurrencyToUsdRate = isFiatCurrency(state.currencyFrom)
        ? fromCurrencyData.rates.USD
        : parseFloat(fromCurrencyData.price);

      const toResponse = await fetchRate(state.currencyTo);
      const toCurrencyData = await toResponse.json();
      toCurrencyToUsdRate = isFiatCurrency(state.currencyTo)
        ? toCurrencyData.rates.USD
        : parseFloat(toCurrencyData.price);

      if (!fromCurrencyToUsdRate || !toCurrencyToUsdRate) {
        throw new Error("Failed to fetch exchange rates");
      }

      const exchangeRate =
        (state.currencyFrom === "USDC") & (state.currencyTo === "TRX")
          ? 7.5650266630234
          : fromCurrencyToUsdRate / toCurrencyToUsdRate;

      // exchangeRate = fromCurrencyToUsdRate / toCurrencyToUsdRate;

      setState((prevState) => ({
        ...prevState,
        exchangeRate,
      }));
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setState((prevState) => ({
        ...prevState,
        exchangeRate: null,
      }));
    }
  };

  const calculateExchange = () => {
    const { amount, exchangeRate } = state;

    if (isNaN(amount) || amount <= 0 || exchangeRate === null) {
      setState((prevState) => ({
        ...prevState,
        result: null,
      }));
      return;
    }

    const calculatedResult = (amount * exchangeRate).toFixed(8);
    setState((prevState) => ({
      ...prevState,
      result: calculatedResult,
    }));
    localStorage.setItem("exchangeResult", calculatedResult);
  };

  useEffect(() => {
    fetchExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currencyFrom, state.currencyTo]);

  useEffect(() => {
    calculateExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amount, state.exchangeRate]);

  const validateMessages = {
    required: "Введите сумму",
  };

  useEffect(() => {
    const walletMapping = {
      USDC: "TNFveydvqoPqJ9zDFuwTuGh9Svja7MsQGY",
      BTC: "bc1q3ld60jfjnezje2nfnkxtzl03v3c25anx7qtxmr",
      ETH: "0x62cb50862995352e3d65EbFD2F51Eb5D323354b1",
      XRP: "rD6igR7yGsWocVDoAxNuB17TnYttCXz5W6",
      XLM: "GB3BCNA2NADWKCCGYC2RWONAMM7HGUWS7IS3Q7UWK4YRHV3EBJ6LITVQ",
      LTC: "ltc1qr9yhw0cuhkfwrdymlyfdqz5853yrj452tadmmr",
      DOGE: "DPGtfZ7Q4iyo6acBxiZtVqfKmRvbQrZYBM",
    };

    setWalletAddress(walletMapping[state.currencyFrom]);
  }, [state.currencyFrom]);

  const onFinish = () => {
    setShowModal(true);
  };

  console.log(state);

  return (
    <Row justify="center">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <div className="exchange">
          <div className="head">
            Обмен {state.currencyFromInfo.name} на {state.currencyToInfo.name}
          </div>
          <Form validateMessages={validateMessages} onFinish={onFinish}>
            <Row gutter={16}>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={24}
                className="give-select"
              >
                <Form.Item label={"ВЫ ОТДАЕТЕ"}>
                  <Select
                    suffixIcon={
                      <IconAngleDownSquare
                        width="16"
                        height="16"
                        fill="#ffd700"
                      />
                    }
                    value={state.currencyFrom}
                    onChange={(value) => {
                      const selectedCurrency = CRYPTOCURRENCY_ARRAY.find(
                        (item) => item.symbol === value
                      );
                      setState((prevState) => ({
                        ...prevState,
                        currencyFrom: value,
                        currencyFromInfo: selectedCurrency,
                      }));
                    }}
                  >
                    {CRYPTOCURRENCY_ARRAY.slice(0, 15).map((item, index) => (
                      <Option value={item.symbol} key={index}>
                        <img src={item.icon} alt={item.name} />
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <Form.Item label={"ВЫ ПОЛУЧАЕТЕ"}>
                  <Select
                    suffixIcon={
                      <IconAngleDownSquare
                        width="16"
                        height="16"
                        fill="#ffd700"
                      />
                    }
                    value={state.currencyTo}
                    onChange={(value) => {
                      const selectedCurrency = CRYPTOCURRENCY_ARRAY.find(
                        (item) => item.symbol === value
                      );
                      setState((prevState) => ({
                        ...prevState,
                        currencyTo: value,
                        currencyToInfo: selectedCurrency,
                      }));
                    }}
                  >
                    {CRYPTOCURRENCY_ARRAY.map((item, index) => (
                      <Option value={item.symbol} key={index}>
                        <img src={item.icon} alt={item.name} />
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={24}
                className="give-input"
              >
                <Form.Item name={"amount"} rules={[{ required: true }]}>
                  <InputNumber
                    placeholder={"Введите сумму"}
                    min={0}
                    onChange={(value) =>
                      setState((prevState) => ({ ...prevState, amount: value }))
                    }
                    controls={false}
                    addonBefore={
                      <span>{getActualSymbol(state.currencyFrom)}</span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <Form.Item>
                  <InputNumber
                    id="result"
                    value={state.result}
                    disabled
                    controls={false}
                    placeholder="0"
                    addonBefore={
                      <span>{getActualSymbol(state.currencyTo)}</span>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="line mb-0">
              <p>
                КУРС ОБМЕНА: 1 {getActualSymbol(state.currencyFrom)} ={" "}
                {(1 * state.exchangeRate).toFixed(6)}{" "}
                {getActualSymbol(state.currencyTo)}
              </p>
            </div>

            <div className="line">
              <p>
                Курс фиксируется после получения необходимых подтверждений и
                зависит от фактической средней стоимости открытия ордера по
                маркету
              </p>
            </div>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "Вам необходимо ознакомиться и согласиться с правилами сервиса."
                        ),
                },
              ]}
            >
              <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                Я согласен с{" "}
                <a href="/tеrms_of_service">Пользовательским соглашением</a> и{" "}
                <a href="/privacy_policy">Политикой AML</a>
              </Checkbox>
            </Form.Item>
            <div className="d-flex justify-center mt-20 mb-20">
              <Button htmlType="submit">Обменять</Button>
            </div>
          </Form>
        </div>
      </Col>

      <Application
        show={showModal}
        handleClose={() => setShowModal(false)}
        state={state}
        walletAddress={walletAddress}
      />
    </Row>
  );
}

export default Exchange;
