import React from "react";

const IconEnvelope = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M18,20.75H6A3.383,3.383,0,0,1,2.25,17V8A3.383,3.383,0,0,1,6,4.25H18A3.383,3.383,0,0,1,21.75,8v9A3.383,3.383,0,0,1,18,20.75ZM6,5.75c-1.577,0-2.25.673-2.25,2.25v9c0,1.577.673,2.25,2.25,2.25H18c1.577,0,2.25-.673,2.25-2.25V8c0-1.577-.673-2.25-2.25-2.25Zm7.029,7.429,4.912-3.573a.749.749,0,0,0-.882-1.212l-4.913,3.572a.248.248,0,0,1-.292,0L6.941,8.394a.749.749,0,1,0-.882,1.212l4.912,3.573a1.748,1.748,0,0,0,2.058,0Z"
      />
    </svg>
  );
};

export default IconEnvelope;
