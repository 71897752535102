import { Col, Row } from "antd";
import React from "react";

const Rules = () => {
  return (
    <main>
      <div className="rules">
        <section>
          <Row>
            <Col lg={24} md={24} xs={24}>
              <h2>Пользовательское соглашение</h2>
              <h4>Термины и определения</h4>
              <p>
                Вебсайт - сайт в сети Интернет по адресу exbox.app
                <br />
                Верификация карты - это проверка принадлежности карты или счета
                её владельцу Заявка - запись в системе управления Сервиса,
                созданная Пользователем путем заполнения формы на Вебсайте,
                содержащая намерение Пользователя произвести обмен одной валюты
                на другую, его идентификационные данные и реквизиты для оплаты.
                <br />
                Криптовалюта - Bitcoin, Litecoin, Ethereum и любые иные валюты,
                основанные на блокчейне.
                <br /> Пользователь - любое физическое лицо, использующее услуги
                сервиса ExBox.app, присоединившееся к настоящему
                Пользовательскому соглашению (далее - Соглашению)
                <br /> Сервис - обменный сервис ExBox
                <br /> Стороны - совместное наименование Сервиса и Пользователя
                <br /> Учетная запись - запись в системе Сервиса, представляющая
                собой средство учета информации о Пользователе, идентификатором
                которой выступает электронная почта, указанная при создании
                заявки и автоматической регистраци. Электронная валюта - Perfect
                Money, Payeer, Advanced Cash и любые иные платежные системы
                классического типа.
              </p>
              <h4>1. Предмет Соглашения</h4>
              <p>
                1.1. Предметом Соглашения, заключаемого между Сторонами путем
                присоединения Пользователя к настоящему Соглашению через
                создание заявки на Вебсайте, является оказание Пользователю
                услуг Сервиса.
                <br />
                1.2. Акцептом Соглашения является совершение Пользователем
                действия по созданию заявки на Вебсайте и его ознакомление с
                условиями настоящего Соглашения.
                <br />
                1.3. Фиксация факта акцепта Пользователем Соглашения
                осуществляется Сервисом в электронном виде и хранится в
                аппаратнопрограммном комплексе Сервиса.
                <br />
                1.4. Администрация ExBox вправе в любое время внести изменения
                или дополнения в настоящее Соглашение, которое вступает в силу
                немедленно после публикации на Вебсайте Сервиса.
              </p>
              <h4>2. Правила пользования Учетной записью</h4>
              <p>
                2.1. Сервис ExBox предназначен для использования исключительно в
                личных целях. Регистрируясь на сайте, Вы даете свое согласие на
                передачу Сервису достоверных данных о себе.
                <br />
                2.2. Мы также можем проводить дополнительные проверки вашей
                информации и запрашивать у вас любые данные, связанные с обменом
                (паспорт, фотографию карты, видео верификацию карты, квитанцию
                об отправке средств и другие необходимые данные) по любой
                причине, связанной с использованием вами Сервиса.
                <br />
                2.3. Администрация Сервиса может в любое время и по своему
                собственному усмотрению отказать вам в возможности использовать
                Учетную запись или приостановить выполнение любой Заявки до
                окончания рассмотрения информации, предоставленной Вами.
                <br />
                2.4. Ответственность за действия третьих лиц. Ваша Учетная
                запись предназначена только для личного использования, а не для
                использования или доступа какой-либо третьей стороны. В любом
                случае, вы несете полную ответственность за все действия или
                бездействие любой третьей стороны, осуществляющей доступ и/или
                использующей Вашу Учетную запись.
                <br />
                2.5. Вы соглашаетесь с тем, что не будете использовать Сервис
                для осуществления какой-либо преступной деятельности, включая,
                помимо прочего, отмывание денег, незаконные азартные игры,
                финансирование терроризма. Если Сервис по своему собственному
                усмотрению определит, что деятельность на вашей Учетной записи
                является подозрительной или связана с какой-либо запрещенной
                деятельностью или незаконной операцией, Сервис может
                приостановить действие вашего Учетной записи, заблокировать
                любые невыполненные транзакции.
              </p>
              <h4>3. Взаимные обязательства Сторон</h4>
              <p>
                3.1. Сервис ExBox берет на себя следующие обязательства: 3.1.1.
                Обеспечивать конвертацию электронных валют, криптовалют и
                придерживаться при этом стандартов, установленных данным
                Соглашением.
                <br />
                3.1.2. Предоставлять поддержку информационного и технического
                характера Пользователям в ходе использования ими оказываемых
                Сервисом услуг.
                <br />
                3.1.3. Гарантировать конфиденциальность персональных сведений
                относительно совершения обменных операций.
                <br />
                3.1.4. Обеспечить перевод средств на счет Пользователя не
                позднее суток после поступления жалобы в ситуациях,
                перечисленных п. п. 3.2.5, 5.4—5.6 Соглашения.
                <br />
                3.1.5. Хранить в тайне и не разглашать информацию по обменным
                операциям, а также личные данные Пользователя третьим лицам, за
                исключением следующих ситуаций: - по законному решению суда - по
                законному запросу компетентных органов - по запросу
                администрации платежных систем
                <br />
                3.2. Пользователь берет на себя следующие обязательства:
                <br />
                3.2.1. Передавать Сервису точные сведения для обеспечения
                оперативного проведения обмена.
                <br />
                3.2.2. Указывать корректные реквизиты для получения средств.
                3.2.3. Иметь возможность получать уведомления от системы на
                электронную почту. Установить антивирусные программы для
                безопасного соединения с Вебсайтом.
                <br />
                3.2.4. Выполнять требования этого Соглашения.
                <br />
                3.2.5. Уведомлять администрацию Сервиса, если средства полностью
                либо частично не были зачислены на счет получателя. А также обо
                всех ситуациях, когда имели место обстоятельства, указанные в п.
                п. 5.4—5.6 этого Соглашения. Уведомление следует отправить в
                администрацию не позднее месяца после наступления
                соответствующего инцидента. Невыполнение этого требования
                приводит к перечислению спорных сумм на счет Сервиса.
                <br />
                3.2.6. Соблюдать законы своей страны нахождения и гражданства.
                3.3. Права Сервиса ExBox:
                <br />
                3.3.1. На время останавливать работу сервиса для устранения
                неполадок и проведения модернизации. <br />
                3.3.2. Остановить проведение транзакции, если поступило
                обращение компетентных органов, технической поддержки
                криптовалютной биржи, партнеров либо жалоба Пользователя на
                мошеннические действия. Обмен останавливается на время,
                необходимое для выяснения ситуации.
                <br />
                3.3.3. Устанавливать и изменять комиссии за проведение обмена.
                <br />
                3.3.4. Ограничить потенциального Пользователя в доступе к своим
                услугам, не объясняя мотивов отказа.
                <br />
                3.3.6. Если в процессе обмена возникла ошибка, то Сервис вправе
                потребовать от Пользователя подтверждения совершенной операции
                путем предоставления подтверждающих файлов (скриншоты страниц
                аккаунта платежных систем, видео входа в аккаунт и прочее).
                <br />
                3.3.7. Заблокировать Пользователя за неадекватное поведение или
                отказ в предоставлении данных, необходимых для решения его
                вопроса.
              </p>

              <h4>4. Регламент совершения обменных операций</h4>
              <p>
                4.1. Началом предоставления услуг считается момент создания
                Заявки Пользователем.
                <br />
                4.2. В Заявке Пользователь указывает:
                <br />
                4.2.1. Отправляемую сумму. Получаемая сумма рассчитывается
                автоматически относительно обменного курса Сервиса и курсов
                криптовалют.
                <br />
                4.2.2. Реквизиты для получения выплаты. 4.3. Сервис
                автоматически присваивает заявке уникальный порядковый номер и
                выдает реквизиты для оплаты.
                <br />
                4.4. Для совершения обмена Пользователь обязуется оплатить
                созданную им Заявку на указанные реквизиты. При этом
                Пользователь лично отвечает за "чистоту" отправляемых средств,
                поскольку предварительная проверка законности этой операции не
                входит в компетенцию Сервиса.
                <br />
                4.5. Сервис приступает к обработке заявки после фактического
                зачисления средств на счет.
                <br />
                4.6. AML проверка является обязательным этапом проведения
                заявки. Нарушение требований к Risk Score может привести к
                остановке выполнения заявки и необходимости верификации личности
                Пользователя.
                <br />
                4.7. Для выполнения заявки Сервис выставляет ордер на покупку по
                рынку на криптовалютной бирже. Вследствие этого курс
                криптовалюты может измениться и привести к уменьшению
                отправляемой суммы.
                <br />
                4.8. Время выполнения заявки указывается в деталях оформления
                заявки и в карточке оформленной заявки. Стандартное время
                выполнения заявки составляет 10 - 20 минут.
                <br />
                4.9. Обмен считается завершенным после отправки запроса
                криптовалютной биржи на вывод средств на реквизиты Пользователя.
              </p>

              <h4>
                5. Дополнительну к регламенту совершения обменных операций
              </h4>
              <p>
                5.1. Сервис не фиксирует курс обмена на момент создания заявки в
                направлениях с криптовалютами. Пересчет получаемой суммы
                производится после получения оплаты от Пользователя.
                <br />
                5.1.1 Сумма заявки может быть пересчитана только в сторону
                уменьшения.
                <br />
                5.2. Для получения платежей в криптовалюте Сервис использует
                корпоративные аккаунты ведущих криптовалютных бирж. Отправляя
                платеж на наши реквизиты, вы принимаете, что средства будут
                зачислены на счет криптовалютной биржи.
                <br />
                5.3. Полученные на биржу средства Сервис автоматически
                конвертирует в Tether USDT по актуальному курсу. При выполнении
                заявки Сервис закупает отправляемую криптовалюту из Tether USDT
                по текущему курсу.
                <br />
                5.4. Сроки зачисления денежных средств на счет зависит от
                выбранного направления обмена, соответствующая информация
                размещена на странице заявки Сервиса.
                <br />
                5.5. Если Пользователь перечислил сумму, величина которой не
                совпадает с указанной в заявке, то Сервис вправе остановить
                операцию и запросить подтверждающие данные для идентификации
                отправителя средств. После идентификации заявка может быть либо
                проведена, либо будет выполнен возврат. В обоих случаях Сервис
                взимает комиссию в размере 5% от суммы обмена, но не менее 30
                USD.
                <br />
                5.6. Поддержка Пользователя производятся через онлайн-чат на
                главной странице сайта или через внутренний чат в карточке
                заявки.
                <br />
                5.7. Сервис не несет ответственности за платежи, выполненные по
                согласию Пользователя третьими лицами. В случае возникневения
                проблем по этой причине, Сервис не несет за это ответственности.
                <br />
                5.8. Сервис запрещено использовать для проведения незаконных
                операций.
              </p>

              <h4>6. Ответственность Сервиса</h4>
              <p>
                6.1. Сервис ExBox несет финансовую ответственность перед
                Пользователем в размере суммы средств, которые были переданы
                Сервису для исполнения Заявки.
                <br />
                6.2. Сервис ExBox не несет ответственности перед Пользователем
                за финансовые потери, вызванные незаконными действиями третьих
                сторон, которые невозможно предсказать или предотвратить.
                <br />
                6.3. Сервис ExBox не несет никакой юридической ответственности
                за последствия неправильного использования сервиса или за
                ошибки, сделанные Пользователем во время заполнения заявки для
                совершения обмена виртуальной валюты. Сервис не может выполнить
                отмену совершенного платежа.
                <br />
                6.4. Сервис не отвечает за задержки платежа либо допущенные в
                его процессе неточности, возникшие вследствие действий банков
                или систем платежей. Обменник не несет ответственности за
                последующие после обменной операции, возможные, блокировки
                банковских карт и аккаунтов платёжных систем пользователя.
                <br />
                6.5. Сервис не компенсирует затраты, причиной которых стали
                технические проблемы обменного сервиса, криптовалютных бирж,
                задержки либо ошибки в процессе совершения обмена.
                <br />
                6.6. Сервис ExBox не несёт ответственности за частичное или
                полное несоблюдение данного Соглашения, если таковое явилось
                следствием обстоятельств непреодолимой силы, которые невозможно
                предотвратить разумными мерами.
              </p>

              <h4>7. Ответственность Пользователя</h4>
              <p>
                7.1. В случае согласия с настоящими Условиями, Пользователь
                заявляет и подтверждает, что:
                <br />
                7.1.1. Он предоставил верную и правдивую информацию о себе, а
                также подлинные идентификационные данные.
                <br />
                7.1.2. Он не является участником операций или сделок по
                отмыванию доходов, полученных преступным путем.
                <br />
                7.1.3. Использование Сервиса не связано с осуществлением
                преступной и/или террористической деятельности.
                <br />
                7.2. Пользователь обязуется соблюдать налоговое законодательство
                страны, резидентом которой он является.
                <br />
                7.3. Пользователь обязуется не пользоваться техническим сбоем на
                стороне обменного пункта, либо некорректной трансляцией курсов
                на мониторингах с целью обогащения. Попытка использования
                Пользователем технического сбоя, при которой Сервис отдает
                больше средств, чем получает, будет расцениваться как
                мошенничество, заявка будет приостановлена, а средства
                возвращены с вычетом комиссии на отправку.
              </p>

              <h4>8. Возврат платежей</h4>
              <p>
                8.1. Выполнение Заявки имеет необратимый эффект, отменить ее
                невозможно.
                <br />
                8.2. Для возврата платежа Сервис закупает требуемую валюту по
                текущему курсу на сумму Tether USDT, которую он получил при
                конвертации входящего депозита.
                <br />
                8.3. Если клиент затребовал возврат платежа по собственной
                инициативе, то Сервис взимает комиссию в размере 2%, но не менее
                30 USD, и комиссию на отправку платежа.
              </p>

              <h4>9. Непредвиденные обстоятельства</h4>
              <p>
                9.1. При возникновении непреодолимых обстоятельств Стороны
                освобождаются от наступления ответственности за невыполнение
                либо несвоевременное выполнение условий данного Соглашения. Под
                непреодолимыми обстоятельствами понимаются война, теракт, пожар,
                наводнение, беспорядки, хакерское проникновение, акты местных
                властей или сбои в энергоснабжении и отсутствие доступа к сетям,
                в числе которых Интернет и услуги связи.
                <br />
                9.2. Если в результате отправки средств Пользователем счет
                Сервиса был заблокирован, то заявка, по которой была получена
                оплата, не проводится и оплата удерживается до выяснения причин
                блокировки.
                <br />
                9.3. Если в результате отправки средств Сервисом счет
                пользователя был заблокирован, то Пользователю следует
                обратиться в техподдержку интернет-банка, онлайн кошелька, биржи
                для выяснений причин блокировки. Процесс общения Пользователя с
                Сервисом должен осуществляться во внутреннем чате в заявке для
                фиксирования процесса разблокировки.
              </p>

              <h4>10. Условия обработки и хранения персональных данных</h4>
              <p>
                10.1. Данные Пользователя не подлежат распространению третьим
                лицам, они сохраняются только на стороне сервиса и служат для
                проведения заявок.
                <br />
                10.2. Если к Сервису обращаются правоохранительные органы,
                техподдержка онлайн-кошельков или криптовалютных бирж для
                разъяснения информации о переводах или о клиентах, то Сервис
                оставляет за собой право передавать персональные сведения.
              </p>
            </Col>
          </Row>
        </section>
      </div>
    </main>
  );
};

export default Rules;
