import { Col, Row } from "antd";
import React from "react";
import { IconLock } from "../utils/Icons";

const Footer = () => {
  return (
    <footer>
      <section>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <a href="/" className="logo">
              <img src={require("../assets/img/logo.png")} alt="" />
              <span>Treexchange</span>
            </a>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="ssl">
              <IconLock width="30" height="30" fill="#fff" />
              <div className="desc">
                <b>SSL Encryption</b>
                <span>100% protection</span>
              </div>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="copy">© 2024 Treexchange. Все права защищены</div>
          </Col>
        </Row>
      </section>
    </footer>
  );
};

export default Footer;
