import { message } from "antd";
import { IconCheckSquare, IconTimesSquare } from "../utils/Icons";

const useMessage = () => {
  const [messageResult, contextHolder] = message.useMessage();

  const successResult = (title) => {
    messageResult.open({
      type: "success",
      duration: 5,
      content: title,
      icon: <IconCheckSquare width="18" height="18" fill="#00c39a" />,
    });
  };

  const errorResult = (title) => {
    messageResult.open({
      type: "error",
      duration: 5,
      content: title,
      icon: <IconTimesSquare width="18" height="18" fill="#d94c48" />,
    });
  };

  return { successResult, errorResult, contextHolder };
};

export default useMessage;
