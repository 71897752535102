import React from "react";

const IconAngleDownSquare = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        id="angle-down-square"
        fill={fill}
        d="M17.625,2.25H6.375c-2.66,0-4.125,1.465-4.125,4.125v11.25c0,2.66,1.465,4.125,4.125,4.125h11.25c2.66,0,4.125-1.465,4.125-4.125V6.375C21.75,3.715,20.285,2.25,17.625,2.25ZM20.25,17.625c0,1.84-.785,2.625-2.625,2.625H6.375c-1.84,0-2.625-.785-2.625-2.625V6.375c0-1.84.785-2.625,2.625-2.625h11.25c1.84,0,2.625.785,2.625,2.625ZM15.53,10.47a.749.749,0,0,1,0,1.06l-3,3a.748.748,0,0,1-1.06,0l-3-3a.75.75,0,0,1,1.06-1.06L12,12.939l2.47-2.469A.749.749,0,0,1,15.53,10.47Z"
      />
    </svg>
  );
};

export default IconAngleDownSquare;
