import React from "react";
import { Col, Row } from "antd";
import Exchange from "./Exchange";

function Banner() {
  return (
    <div className="banner">
      <section>
        <Row align="middle">
          <Col xl={10} lg={24} md={24} sm={24} xs={24}>
            <h1>Покупайте и продавайте криптовалюту</h1>
            <p>Быстрый и безопасный способ покупки или обмена криптовалют</p>
          </Col>
          <Col xl={14} lg={24} md={24} sm={24} xs={24}>
            <Exchange />
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default Banner;
